/* 🔹 Estilos Generales */
.home-container {
    font-family: "Poppins", sans-serif;
    color: #000000;
    background:#e9ecef;
    padding: 20px;
  }
  
/* 🌟 Sección 1: Hero */
.hero-section {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80vh;
  padding: 20px;
  gap: 30px;
  background: url("/home/cerouno/Documents/shwebs/electro-s/esd/public/images/vector_esd2.png") no-repeat center;
  background-size:contain
}


  
  /* 📌 Texto de la izquierda */
  .text-container {
    flex: 1;
    text-align: left;
    font-size: 2rem;
    font-weight: bold;
    animation: slideInLeft 1.5s ease-out forwards;
    margin-top: 8%;
  }
  
  .cta-button {
    background: linear-gradient(45deg, #002ba0, #002f9e);
    color: white;
    padding: 12px 25px;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    font-size: 18px;
    font-weight: bold;
    transition: all 0.3s ease-in-out;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    display: inline-block;
    margin-top: 20px;
  }
  
  .cta-button:hover {
    transform: scale(1.1);
    background: linear-gradient(45deg, #000b83, #0051a9);
  }
  
  .video-container {
    flex: 1;
    max-width: 50%;
    overflow: hidden;
    border-radius: 10px;
    display: flex; /* ✅ Asegura que se renderice correctamente */
    justify-content: center;
    align-items: center;
  }
  
  .video-container video {
    width: 100%;
    height: auto;
    display: block;
    object-fit: cover; /* ✅ Ajusta el video para que se vea bien */
  }
  
 
  /* 🔥 Sección 3: Carrusel de Marcas */
  .brands-section {
    margin-top: 10px;
    padding: 30px 20px;
    background: #d6d6d694;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px; /* Espaciado entre marcas */
  }
  
  /* Botón de marca */
  .brand-button {
    background: transparent;
    border: none;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 220px; /* Tamaño por defecto */
    max-width: 100%;
  }
  
  /* Imagen de la marca */
  .brand-button img {
    width: 210px; /* Tamaño base */
    max-width: 100%;
    height: auto;
    filter: grayscale(50%);
    transition: filter 0.3s ease-in-out, transform 0.3s ease-in-out;
    position: relative;
  }
  
  .brand-button:hover img {
    filter: grayscale(0%);
    transform: scale(1.1);
  }
  
  /* 🎬 🔥 ANIMACIONES */
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(30px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes slideInLeft {
    from {
      opacity: 0;
      transform: translateX(-50px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  /* 🔹 Responsividad */
  @media (max-width: 1024px) { /* Tablets */
    .brands-section {
      gap: 20px;
    }
  
    .brand-button {
      width: 180px; /* Reducción de tamaño */
    }
  
    .brand-button img {
      width: 180px; /* Ajuste de tamaño */
    }
  }
  
  @media (max-width: 768px) { /* Móviles */
    .brands-section {
      gap: 15px; /* Más espacio entre elementos */
    }
  
    .brand-button {
      width: 140px; /* Más pequeño para pantallas reducidas */
    }
  
    .brand-button img {
      width: 140px; /* Ajuste de tamaño */
    }
  }





  /* 🔥 Sección 3: Servicios */
  .services-section {
    display: flex;
    justify-content: space-around;
    margin-top: 10px;
    padding: 40px 20px;
    gap: 20px;
    opacity: 0;
    animation: fadeIn 1.5s ease-out forwards 0.5s;
  }
  
  .service-box {
    background: rgba(255, 255, 255, 0.864);
    border-radius: 10px;
    text-align: center;
    padding: 20px;
    max-width: 300px;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    position: relative;
    overflow: hidden;
  }
  
  .service-box img {
    width: 100%;
    border-radius: 10px;
    transition: transform 0.3s ease-in-out;
  }
  
  .service-box:hover {
    transform: translateY(-10px);
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
  }
  
  .service-box:hover img {
    transform: scale(1.05);
  }
  
  .service-box button {
    background: #0300bf;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
    transition: background 0.3s ease-in-out;
  }
  
  .service-box button:hover {
    background: #00369b;
  }
 

  /* 🔥 RESPONSIVE */
  @media (max-width: 1024px) {
    .hero-section {
      flex-direction: column;
      text-align: center;
      height: auto;
    }
  
    .text-container {
      font-size: 1.5rem;
    }
  
    .video-container {
      max-width: 90%;
    }
  
    .services-section {
      flex-wrap: wrap;
      justify-content: center;
    }
  
    .service-box {
      max-width: 90%;
      margin-bottom: 20px;
    }
  }
  
  @media (max-width: 768px) {
    .cta-button {
      font-size: 16px;
      padding: 10px 20px;
    }
  
  }
  
/* 🔹 Estilos Generales */
:root {
  --primary-color: #007bff;
  --secondary-color: #00d4ff;
  --bg-color: #ffffff;
  --text-color: #000000;
  --shadow-color: rgba(0, 0, 0, 0.15);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Poppins", sans-serif;
  background-color: var(--bg-color);
  color: var(--text-color);
}

.home-container {
  padding: 20px;
}

/* 🚀 Sección 2: Servicios */
.services-section {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 30px;
  padding: 40px 20px;
}

.service-box {
  background: white;
  border-radius: 10px;
  text-align: center;
  padding: 20px;
  max-width: 300px;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  box-shadow: 0 10px 20px var(--shadow-color);
}

.service-box img {
  width: 100%;
  border-radius: 10px;
  transition: transform 0.3s ease-in-out;
}

.service-box:hover {
  transform: translateY(-10px);
  box-shadow: 0px 15px 25px var(--shadow-color);
}

.service-box button {
  background: #0036b3;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  transition: background 0.3s ease-in-out;
}

.service-box button:hover {
  background: #2b41cf;
}


/* 📱 Responsive - Poner el video arriba en móviles */
@media (max-width: 1024px) {
  .hero-section {
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 5vh 5vw;
  }

  /* ✅ Video primero en móviles */
  .video-container {
    order: -1; /* Mueve el video arriba */
    max-width: 90%;
  }

  /* ✅ Texto más pequeño y centrado */
  .text-container {
    font-size: 1.2rem;
    max-width: 80%;
  }

  .text-container h1 {
    font-size: 1.8rem;
    font-weight: 600;
  }

  .text-container p {
    font-size: 1rem;
    opacity: 0.8;
  }

  .cta-button {
    font-size: 14px;
    padding: 10px 18px;
  }
}

@media (max-width: 768px) {
  .video-container {
    max-width: 100%;
  }

  .text-container {
    font-size: 1rem;
    max-width: 90%;
  }

  .text-container h1 {
    font-size: 1.5rem;
  }

  .text-container p {
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .hero-section {
    padding: 5vh 3vw;
  }

  .text-container {
    font-size: 0.9rem;
    max-width: 95%;
  }

  .text-container h1 {
    font-size: 1.3rem;
  }

  .text-container p {
    font-size: 0.85rem;
  }

  .cta-button {
    font-size: 13px;
    padding: 8px 16px;
  }
}