/* 🔥 Contenedor de todos los servicios */
.services-container {
    display: flex;
    flex-direction: column;
    gap: 50px;
    padding: 50px;
    background: #f8f9fa;
  }
  
  /* 🔥 Tarjeta de Servicio */
  .service-card {
    display: flex;
    align-items: center;
    width: 100%;
    height: 70vh; /* Ocupa el 70% de la altura de la pantalla */
    background: white;
    border-radius: 12px;
    box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    animation: fadeIn 1s ease-in-out;
  }
  
  /* 🔹 Imagen del servicio (lado izquierdo) */
  .service-image {
    width: 50%;
    height: 100%;
    object-fit: cover;
  }
  
  /* 🔹 Detalles del servicio (lado derecho) */
  .service-details {
    width: 50%;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .service-details h2 {
    font-size: 32px;
    color: #0a4cff;
    margin-bottom: 15px;
  }
  
  .service-details p {
    font-size: 18px;
    color: #555;
    margin-bottom: 20px;
  }
  
  .service-details ul {
    list-style: none;
    padding: 0;
    margin-bottom: 20px;
  }
  
  .service-details li {
    font-size: 16px;
    color: #333;
    margin-bottom: 10px;
  }
  
  /* 🔥 Botón de WhatsApp */
  .whatsapp-button {
    background: linear-gradient(45deg, #25d366, #1ebc57);
    color: white;
    padding: 14px 24px;
    border: none;
    border-radius: 8px;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    display: inline-block;
    transition: background 0.3s ease-in-out, transform 0.2s ease-in-out;
  }
  
  .whatsapp-button:hover {
    background: linear-gradient(45deg, #1ebc57, #25d366);
    transform: scale(1.05);
  }
  
  /* 🔥 Animación de entrada */
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* 📱 Responsive para pantallas medianas */
  @media (max-width: 1024px) {
    .service-card {
      height: auto;
      flex-direction: column;
    }
  
    .service-image {
      width: 100%;
      height: 300px;
    }
  
    .service-details {
      width: 100%;
      text-align: center;
      padding: 30px;
    }
  
    .service-details h2 {
      font-size: 28px;
    }
  
    .service-details p {
      font-size: 16px;
    }
  
    .whatsapp-button {
      font-size: 16px;
      padding: 12px 20px;
    }
  }
  @media (max-width: 480px) {
    .services-container {
      padding: 20px;
      gap: 30px;
    }
  
    .service-card {
      flex-direction: column;
      height: auto;
      border-radius: 8px;
    }
  
    .service-image {
      width: 100%;
      height: 220px;
    }
  
    .service-details {
      padding: 16px;
    }
  
    .service-details h2 {
      font-size: 20px;
      margin-bottom: 10px;
    }
  
    .service-details p {
      font-size: 13px;
      margin-bottom: 15px;
    }
  
    .service-details ul li {
      font-size: 13px;
    }
  
    .whatsapp-button {
      font-size: 13px;
      padding: 10px 16px;
    }
  }
  