/* 🎨 Estilos generales */
.experience-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-end;
  gap: 4vw;
  padding: 5vh 5vw;
  background: #a7a7a7;
  color: white;
  text-align: center;
  border-radius: 15px;
}

/* 📊 Caja de estadísticas */
.stat-box {
  position: relative;
  width: 160px;
  height: 220px;
  background: rgba(255, 255, 255, 0.95);
  border-radius: 15px;
  padding: 20px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  font-family: "Poppins", sans-serif;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.15);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.stat-box:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
}

/* 📊 Barra animada */
.bar {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 0%;
  background: linear-gradient(45deg, #00c853, #1b5e20);
  border-radius: 0 0 15px 15px;
}

/* 📊 Barra especial para "Años de Experiencia" */
.years-box {
  background: rgba(255, 215, 0, 0.9);
}

.years-bar {
  background: linear-gradient(45deg, #ffc107, #ff9800);
}

/* 📊 Títulos */
.stat-box h2 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
  color: black;
  z-index: 2;
}

/* 📊 Descripciones */
.stat-box p {
  font-size: 16px;
  opacity: 0.8;
  font-weight: 500;
  z-index: 2;
}
