/* 🔥 Estilos generales */
body {
  font-family: "Poppins", sans-serif;
  background: #f4f6f9;
  color: #333;
  margin: 0;
  padding: 0;
}

/* 🔹 Contenedor principal */
.products-container {
  text-align: center;
  padding: 60px;
  max-width: 1200px;
  margin: auto;
}

/* 🔥 Título */
.products-container h1 {
  font-size: 40px;
  margin-bottom: 30px;
  color: #003366;
  text-transform: uppercase;
  letter-spacing: 1.5px;
}

/* 🔥 Filtros */
.filters {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 30px;
}

.filters select {
  padding: 12px;
  font-size: 18px;
  border: 2px solid #004386;
  border-radius: 8px;
  cursor: pointer;
  background: white;
  color: #0053a7;
  font-weight: bold;
  transition: all 0.3s ease-in-out;
}

.filters select:hover {
  background: #006cd7e2;
  color: white;
}

/* 🔥 Botón de limpiar filtros */
.clear-filters {
  background: linear-gradient(45deg, #ff4b4b, #ff0000);
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  box-shadow: 0px 4px 10px rgba(255, 0, 0, 0.3);
}

.clear-filters:hover {
  background: linear-gradient(45deg, #ff0000, #cc0000);
  transform: scale(1.07);
  box-shadow: 0px 6px 15px rgba(255, 0, 0, 0.5);
}

.clear-filters:active {
  transform: scale(0.95);
  box-shadow: 0px 2px 5px rgba(255, 0, 0, 0.2);
}

/* 🔥 Responsive */
@media (max-width: 768px) {
  .clear-filters {
    font-size: 14px;
    padding: 10px 18px;
  }
}


/* 🔹 Grid de productos */
.products-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  padding: 20px;
  justify-content: center;
}

/* 🔹 Tarjeta de producto */
.product-card {
  background: white;
  border-radius: 12px;
  text-align: center;
  padding: 25px;
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.15);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  position: relative;
  overflow: hidden;
  animation: fadeIn 0.8s ease-in-out;
}

.product-card:hover {
  transform: translateY(-12px);
  box-shadow: 0px 12px 25px rgba(0, 0, 0, 0.25);
}

/* 🔹 Imagen del producto */
.product-card img {
  width: 100%;
  height: 220px;
  object-fit: cover;
  border-radius: 12px;
  transition: transform 0.3s ease-in-out;
}

.product-card:hover img {
  transform: scale(1.07);
}

/* 🔹 Título y descripción */
.product-card h3 {
  font-size: 22px;
  margin: 15px 0;
  color: #007bff;
}

.product-card p {
  font-size: 16px;
  opacity: 0.9;
  margin-bottom: 20px;
}

/* 🔹 Botones */
.product-card button,
.whatsapp-button {
  background: linear-gradient(45deg, #0011d0, #0068fa);
  color: white;
  padding: 12px 22px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  transition: all 0.3s ease-in-out;
  margin: 8px;
}

.product-card button:hover,
.whatsapp-button:hover {
  background: linear-gradient(45deg, #2a81f3, #3159f8);
  transform: scale(1.08);
}




/* 🔥 Modal de detalles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
}

.modal-overlay.active {
  opacity: 1;
  visibility: visible;
}

.modal-content {
  background: #ffffff;
  padding: 3rem;
  width: 90%;
  max-width: 600px;
  border-radius: 12px;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.3);
  text-align: center;
  position: relative;
  animation: fadeIn 0.3s ease-in-out;
}

.close-button {
  position: absolute;
  top: 12px;
  right: 15px;
  background: none;
  border: none;
  font-size: 26px;
  cursor: pointer;
  color: red;
  transition: transform 0.2s ease-in-out;
}

.close-button:hover {
  transform: scale(1.3);
}

/* 📸 Imagen en el Modal */
.main-image {
  width: 100%;
  max-height: 280px;
  object-fit: cover;
  border-radius: 10px;
  margin-bottom: 1rem;
}

/* 🔹 Botón de WhatsApp */
.whatsapp-button {
  background: #25d366;
  font-size: 18px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-top: 1rem;
}

.whatsapp-button:hover {
  background: #1ebc57;
  transform: scale(1.05);
}

/* 🔥 Animaciones */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

/* 📱 Responsive Mejorado */
@media (max-width: 1024px) {
  .products-container h1 {
    font-size: 36px;
  }
}

@media (max-width: 768px) {
  .products-grid {
    grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
  }

  .product-card {
    padding: 20px;
  }

  .product-card h3 {
    font-size: 20px;
  }

  .product-card button {
    font-size: 14px;
    padding: 10px 18px;
  }

  .modal-content {
    padding: 2rem;
  }

  .whatsapp-button {
    font-size: 16px;
    padding: 10px 20px;
  }
}

@media (max-width: 480px) {
  .products-container h1 {
    font-size: 28px;
  }

  .products-grid {
    grid-template-columns: 1fr;
  }

  .product-card img {
    height: 180px;
  }

  .modal-content {
    width: 95%;
    padding: 1.5rem;
  }

  .whatsapp-button {
    font-size: 14px;
    padding: 8px 16px;
  }
}
