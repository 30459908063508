.footer {
    width: 100%;
    background: linear-gradient(93deg, rgba(3,26,83,1) 22%, rgba(0,49,140,1) 35%, rgba(0,7,110,1) 48%, rgba(2, 0, 75, 0.901) 63%, rgb(0, 3, 62) 75%);
    text-align: center;
    padding: 2rem 0;
    border-top: 2px solid rgba(255, 255, 255, 0.2);
    position: relative;
}

.footer-content {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
}

.footer-phrase {
    font-size: 1.2rem;
    font-weight: 400;
    font-style: italic;
    text-align: center;
    max-width: 800px;
    color: rgba(255, 255, 255, 0.9);
}

.footer-nav {
    display: flex;
    gap: 1.2rem;
}

.footer-button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    background: rgba(255, 255, 255, 0.2);
    color: white;
    border: 1px solid rgba(255, 255, 255, 0.4);
    border-radius: 30px;
    padding: 0.6rem 1.5rem;
    font-size: 1rem;
    cursor: pointer;
    transition: all 0.3s ease;
}

.footer-button:hover {
    background: rgba(255, 255, 255, 0.3);
    transform: scale(1.07);
    border-color: white;
}

.footer-button .icon {
    font-size: 1.2rem;
}

.footer-copy {
    font-size: 0.85rem;
    font-weight: 300;
    color: rgba(255, 255, 255, 0.7);
    margin-top: 1rem;
}

@media (max-width: 768px) {
    .footer-nav {
        flex-direction: column;
        gap: 1rem;
    }

    .footer-button {
        width: 100%;
        padding: 0.8rem;
        justify-content: center;
    }
}
