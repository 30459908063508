.contact-section {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 100px 60px;
    background: linear-gradient(135deg, #ffffff, #f0f4f8);
    min-height: 80vh;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
  
  .contact-card {
    display: flex;
    flex-direction: row;
    max-width: 1100px;
    width: 100%;
    background: #ffffff;
    border-radius: 24px;
    box-shadow: 20px 20px 60px #d9d9d9, -20px -20px 60px #ffffff;
    overflow: hidden;
    transition: transform 0.5s ease, box-shadow 0.3s ease;
    animation: riseUp 1s ease-out;
  }
  
  .contact-card:hover {
    transform: scale(1.015);
    box-shadow: 0 30px 50px rgba(0, 0, 0, 0.1);
  }
  
  .contact-info {
    flex: 1.5;
    padding: 50px;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-right: 1px solid #eee;
  }
  
  .contact-info p {
    font-size: 20px;
    color: #333;
    margin: 20px 0;
    transition: color 0.3s;
  }
  
  .contact-info p:hover {
    color: #0a4cff;
  }
  
  .contact-info span {
    display: inline-block;
    font-weight: 600;
    color: #0a4cff;
    margin-right: 8px;
  }
  
  .contact-action {
    flex: 1;
    background: linear-gradient(45deg, #002ba0, #002f9e);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px;
  }
  
  .contact-button {
    background: #ffffff;
    color: #0a4cff;
    padding: 18px 32px;
    border: none;
    border-radius: 12px;
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 10px 20px rgba(10, 76, 255, 0.2);
  }
  
  .contact-button:hover {
    background: #e6f0ff;
    transform: scale(1.05);
    box-shadow: 0 12px 25px rgba(10, 76, 255, 0.3);
  }
  
  /* Animación de entrada */
  @keyframes riseUp {
    from {
      opacity: 0;
      transform: translateY(40px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Responsive */
  @media (max-width: 992px) {
    .contact-card {
      flex-direction: column;
      border-radius: 20px;
    }
  
    .contact-info, .contact-action {
      padding: 30px;
      text-align: center;
    }
  
    .contact-button {
      padding: 16px 28px;
      font-size: 16px;
    }
  }
  
  @media (max-width: 576px) {
    .contact-section {
      padding: 60px 20px;
    }
  
    .contact-info p {
      font-size: 17px;
    }
  
    .contact-button {
      font-size: 15px;
      padding: 14px 24px;
    }
  }
  