/* 🔹 Estilo General del Navbar */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 2rem;
  background: linear-gradient(93deg, rgba(3,26,83,1) 22%, rgba(0,49,140,1) 35%, rgba(0,7,110,1) 48%, rgba(2, 0, 75, 0.901) 63%, rgb(0, 3, 62) 75%);
  box-shadow: 0px 4px 10px rgb(0, 0, 0);
  position: sticky;
  top: 0;
  z-index: 1000;
  height: 89px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

/* ✅ Navbar scrolleado */
.navbar.scrolled {
  background:  linear-gradient(93deg, rgb(0, 24, 85) 22%, rgba(0, 49, 140, 0.935) 35%, rgb(0, 4, 63) 48%, rgba(0, 20, 94, 0.994) 63%, rgba(2, 0, 112, 0.935) 75%);
  box-shadow: 0px 6px 15px rgb(0, 45, 244);
}
/* 🔥 Contenedor del logo y el texto */
.navbar-brand {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

/* 🔥 Logo como botón */
.navbar-logo {
  width:70px;
  height: 70px;
  object-fit: cover;
  border-radius: 50%;
  background: white;
  padding: 5px;
  box-shadow: 0px 5px 15px rgba(255, 255, 255, 0.8);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* 🔥 Texto circular */
.logo-text-circle {
  width: 90px;
  height: 90px;
  fill: #ffffff;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  animation: rotateText 6s linear infinite;
}

/* 🌟 Animación para rotar el texto */
@keyframes rotateText {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* 🔥 Links del Navbar */
.navbar-links {
  display: flex;
  gap: 1.5rem;
}

.navbar-links a {
  text-decoration: none;
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  transition: color 0.3s ease-in-out;
}

.navbar-links a:hover {
  color: #0a4cff;
  text-shadow: 0px 0px 15px rgba(0, 77, 160, 0.8);
}

/* 🍔 Botón Hamburguesa Mejorado */
.navbar-toggle {
  display: none;
  flex-direction: column;
  gap: 6px;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}

/* Efecto hover en la hamburguesa */
.navbar-toggle:hover {
  transform: scale(1.1);
}

/* Barras de la hamburguesa */
.navbar-toggle .bar {
  width: 32px;
  height: 4px;
  background: #ffffff;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
}

/* 🍔 Animación del botón hamburguesa */
.navbar-toggle.open .bar:nth-child(1) {
  transform: translateY(9px) rotate(45deg);
}

.navbar-toggle.open .bar:nth-child(2) {
  opacity: 0;
}

.navbar-toggle.open .bar:nth-child(3) {
  transform: translateY(-9px) rotate(-45deg);
}

/* 📱 Hacer que la hamburguesa sea visible en móviles */
@media (max-width: 768px) {
  .navbar-toggle {
    display: flex;
  }

  .navbar-toggle .bar {
    width: 30px;
    height: 3.5px;
  }
}


/* 📱 RESPONSIVE */
@media (max-width: 768px) {
  .navbar {
    height: 80px;
    padding: 1.2rem;
  }

  .navbar-logo {
    width: 45px; /* Más pequeño en móviles */
  }

  .logo {
    font-size: 0.9rem;
  }

  .navbar-links {
    flex-direction: column;
    position: absolute;
    top: 100%;
    right: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.95);
    text-align: center;
    transform: translateY(-120%);
    transition: transform 0.3s ease-in-out;
    padding: 1.5rem 0;
  }

  .navbar-links.open {
    transform: translateY(0%);
  }

  .navbar-links a {
    display: block;
    padding: 12px 0;
    font-size: 1.2rem;
  }

  /* 🍔 Menú Hamburguesa */
  .navbar-toggle {
    display: flex;
  }

  .navbar-toggle .bar {
    width: 30px;
    height: 4px;
  }
}

@media (max-width: 480px) {
  .navbar {
    height: 85px;
  }

  .navbar-logo {
    width: 40px;
  }

  .logo {
    font-size: 0.85rem;
  }

  .navbar-links {
    padding: 2rem 0;
  }

  .navbar-links a {
    font-size: 1.1rem;
  }

  .navbar-toggle .bar {
    width: 28px;
    height: 3px;
  }
}
